import { Avatar, Flex, Text } from "@fluentui/react-northstar";
import React from "react";
import userSvg from "../../../../Assets/images/svg/user.svg";
import moment from "moment";

const ReviewCard = ({ review }) => {
  return (
    <Flex column gap="gap.small">
      <Text
        content={moment(review.createdAt).format("MMMM D, YYYY")}
        size="medium"
        weight="bold"
        color="brand"
      />
      <Text content={review.comment} />
      <Flex gap="gap.medium">
        <Avatar
          image={review.userData.image}
          name={review.userData.name}
          size="large"
        />

        <Flex column>
          <Text
            content={review.userData.name}
            size="large"
            weight="bold"
            color="brand"
          />
          <Text
            content={`${review.userData.role} ${
              review.userData?.fieldOfStudy
                ? `, ${review.userData?.fieldOfStudy}`
                : ""
            }`}
            size="medium"
            weight="semibold"
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ReviewCard;
