import React from "react";
import {
  Avatar,
  Button,
  Segment,
  Text,
  Flex,
  Label,
  Image,
  Breadcrumb,
  ChevronEndMediumIcon,
  AcceptIcon,
  Divider,
  Loader,
} from "@fluentui/react-northstar";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import userSvg from "../../../../Assets/images/svg/user.svg";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useBooking } from "../../../../context/BookingContext";
import ReviewCard from "./ReviewCard";
import * as microsoftTeams from "@microsoft/teams-js";
import { useTranslation } from "react-i18next";

const Profile = (props) => {
  const { t } = useTranslation();

  const { userProfileData: user } = props;
  const { mail, role } = useParams();
  const history = useHistory();
  const { setSelectedTab, reviews, isLoadingReviews } = useBooking();

  const openTeamsChat = () => {
    const teamsChatUrl = `https://teams.microsoft.com/l/chat/0/0?users=${mail}`;

    if (props.isInTeams) {
      // Open the chat inside the Microsoft Teams app
      microsoftTeams.executeDeepLink(teamsChatUrl);
    } else {
      // Redirect to Microsoft Teams in the browser or desktop app
      window.open(teamsChatUrl, "_blank");
    }
  };

  const askMeAbout = user?.ProfileSpecialities?.length
    ? user?.ProfileSpecialities.map((speciality) => speciality.title)
    : [];
  const translation = t("booking").tutorProfile;
  return (
    <div className="tutor-profile-wrapper">
      <Breadcrumb aria-label="breadcrumb" className="breadcrumb">
        <Breadcrumb.Item>
          <Breadcrumb.Link
            onClick={() => {
              history.push("/booking");
              setSelectedTab("Tutors");
            }}
          >
            {translation.findTutors}
          </Breadcrumb.Link>
        </Breadcrumb.Item>
        <Breadcrumb.Divider>
          <ChevronEndMediumIcon />
        </Breadcrumb.Divider>
        <Breadcrumb.Item>
          <Breadcrumb.Link>
            {user?.firstName ? `${user?.firstName}` : "Tutor"}
          </Breadcrumb.Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <Flex gap="gap.medium" column>
        <Flex className="personal-info-wrapper" gap="gap.large" wrap>
          <Flex
            gap="gap.large"
            vAlign="center"
            style={{
              maxWidth: props.isMobileView ? "100%" : "50%",
              width: "100%",
            }}
          >
            <Avatar
              image={user?.image ? user.image : userSvg}
              name={user?.firstName ? `${user?.firstName}` : "Tutor"}
              size="largest"
            />
            <Flex column>
              <Text
                content={user?.firstName ? `${user?.firstName}` : "Tutor"}
                size="larger"
                weight="bold"
              />
              <Flex gap="gap.medium">
                <Text
                  content={`${
                    user?.yearOfStudy ? `Year ${user?.yearOfStudy}` : ""
                  } `}
                  size="medium"
                />
                <Text
                  content={`${
                    user?.fieldOfStudy ? `  • ${user.fieldOfStudy}` : ""
                  } `}
                  size="medium"
                />
              </Flex>
              <Text
                content={`${
                  user?.instituteName
                    ? user.instituteName
                    : user?.schoolInfo?.School ?? ""
                }`}
                size="medium"
              />
            </Flex>
          </Flex>
          <Flex
            gap="gap.medium"
            vAlign="end"
            style={{ marginTop: props.isMobileView ? "15px" : 0 }}
          >
            <Button
              content={translation.sendMessage}
              style={{ borderRadius: "25px" }}
              onClick={openTeamsChat}
            />
            <Button
              content={translation.scheduleAMeeting}
              primary
              style={{ borderRadius: "25px" }}
              onClick={() => {
                history.push(`/schedule-meeting/${mail}/${role}`);
              }}
            />
          </Flex>
        </Flex>
        <Flex
          gap="gap.large"
          style={{
            padding: props.isMobileView ? "" : "20px 40px",
            justifyContent: "space-between",
          }}
          wrap
        >
          <div className="left-section">
            <div className="section">
              <Text
                content={translation.askMeAbout}
                className="sub-heading"
                size="large"
                weight="bold"
              />
              <Flex wrap gap="gap.medium">
                {user?.ProfileSpecialities?.length ? (
                  <Text content={askMeAbout.join(", ")} />
                ) : (
                  <Flex
                    className="empty-data-block"
                    vAlign="center"
                    hAlign="center"
                  >
                    {translation.notProvided}
                  </Flex>
                )}
              </Flex>
            </div>
            <div className="section">
              <Text
                content={translation.skills}
                className="sub-heading"
                size="large"
                weight="bold"
              />
              <Flex wrap gap="gap.medium">
                {user?.ProfileSkills?.length ? (
                  user.ProfileSkills.map((interest, index) => (
                    <Label
                      key={index}
                      content={interest.title}
                      circular
                      color="brand"
                      icon={<AcceptIcon />}
                      iconPosition="start"
                      className="badge"
                    />
                  ))
                ) : (
                  <Flex
                    className="empty-data-block"
                    vAlign="center"
                    hAlign="center"
                  >
                    {translation.noSkills}
                  </Flex>
                )}
              </Flex>
            </div>
            <div className="section">
              <Text
                content={translation.interests}
                className="sub-heading"
                size="large"
                weight="bold"
              />
              <Flex wrap gap="gap.medium">
                {user?.ProfileInterests?.length ? (
                  user?.ProfileInterests.map((skill, index) => (
                    <Label
                      key={index}
                      content={skill.title}
                      circular
                      color="brand"
                      icon={<BookmarkIcon htmlColor="#fff" />}
                      className="badge"
                      iconPosition="start"
                    />
                  ))
                ) : (
                  <Flex
                    className="empty-data-block"
                    vAlign="center"
                    hAlign="center"
                  >
                    {translation.noInterests}
                  </Flex>
                )}
              </Flex>
            </div>
          </div>
          <Flex column gap="gap.medium" className="right-section">
            <Text
              content={translation.reviews}
              className="sub-heading"
              size="large"
              weight="bold"
            />
            {isLoadingReviews ? (
              <Flex vAlign="center" hAlign="center">
                <Loader />
              </Flex>
            ) : reviews.length ? (
              reviews.map((review, i) => (
                <React.Fragment key={`booking-review-${i}`}>
                  <ReviewCard review={review} />
                  <Divider />
                </React.Fragment>
              ))
            ) : (
              <Flex hAlign="center">
                <Text content={translation.noReviews} />
              </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>
    </div>
  );
};

export default Profile;
