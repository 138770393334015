import React, { useEffect } from "react";
import "./Tutors.scss";
import TutorCard from "./TutorCard/TutorCard";
import { Col, Row } from "antd";
import { useBooking } from "../../../../context/BookingContext";
import { Flex, Image, Loader } from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import NoTutorsImage from "../../../../Assets/images/svg/no_tutors.svg";

const Tutors = () => {
  const { t } = useTranslation();
  const {
    handleGetTutors,
    tutors,
    checkIsTutorStarred,
    getStarredTutors,
    isLoadingTutors,
    globalSearch,
  } = useBooking();
  useEffect(() => {
    getStarredTutors();
    handleGetTutors();
  }, []);

  const filteredTutors = globalSearch?.length
    ? tutors.filter((tutor) =>
        String(tutor?.name)?.toLowerCase().includes(globalSearch.toLowerCase())
      )
    : tutors;

  const translation = t("booking").tutors;

  return (
    <div style={{ height: "calc(100% - 145px)" }}>
      <h1 style={{ color: "#6264a7", fontWeight: "bold" }}>
        {translation.header}
      </h1>

      {isLoadingTutors ? (
        <Flex hAlign="center" vAlign="center" style={{ height: "100%" }}>
          <Loader size="medium" />
        </Flex>
      ) : tutors.length ? (
        <>
          <Flex
            className="tutors-top-banner"
            styles={{
              position: "relative",
              backgroundImage:
                'url("https://images.pexels.com/photos/1391498/pexels-photo-1391498.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1")',
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              "::before": {
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background:
                  "linear-gradient(180deg, rgba(98, 100, 167, 0.7), rgba(98, 100, 167, 0.3))",
                zIndex: 1,
                borderRadius: "15px",
              },
              zIndex: 0,
            }}
          />
          <Row gutter={[20, 20]}>
            {filteredTutors.map((tutor, i) => {
              const isStarred = checkIsTutorStarred(tutor.email);
              return (
                <Col key={`tutor-${i + 1}`} xxl={4} xl={6} md={12} sm={24}>
                  <TutorCard
                    tutor={tutor}
                    isStarred={isStarred}
                    isFromStarredPage={false}
                  />
                </Col>
              );
            })}
          </Row>
        </>
      ) : (
        <Flex column hAlign="center" vAlign="center">
          <Image
            src={NoTutorsImage}
            style={{ maxWidth: "280px", width: "100%" }}
          />
          <span>{translation.noTutorsFound}</span>
        </Flex>
      )}
    </div>
  );
};

export default Tutors;
