import axios from "axios";
import moment from "moment";
import { createContext, useContext, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import { NotificationAlert } from "../components/Notification/Notification";
import { msalConfig } from "../App/msal-auth/msalAuth";
import { PublicClientApplication } from "@azure/msal-browser";

const Booking = createContext();
export const useBooking = () => {
  return useContext(Booking);
};

const defaultDays = [
  { label: "Sunday", value: "sun", isSelected: false },
  { label: "Monday", value: "mon", isSelected: false },
  { label: "Tuesday", value: "tue", isSelected: false },
  { label: "Wednesday", value: "wed", isSelected: false },
  { label: "Thursday", value: "thu", isSelected: false },
  { label: "Friday", value: "fri", isSelected: false },
  { label: "Saturday", value: "sat", isSelected: false },
];

export const BookingContext = (props) => {
  const { children, user } = props;
  const history = useHistory();
  const [globalSearch, setGlobalSearch] = useState("");
  const [date, setDate] = useState(moment());
  const [bookings, setBookings] = useState([]);
  const [filteredBookings, setFilteredBookings] = useState([]);
  const [isLoadingBookings, setIsLoadingBookings] = useState([]);
  const [tutors, setTutors] = useState([]);
  const [isLoadingTutors, setIsLoadingTutors] = useState(false);
  const [isLoadingStarredTutors, setIsLoadingStarredTutors] = useState(false);
  const [starredTutors, setStarredTutors] = useState([]);
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [scheduledSlots, setScheduledSlots] = useState([]);
  const [isScheduling, setIsScheduling] = useState(false);
  const [isShowingScheduledSlots, setIsShowingScheduledSlots] = useState(false);
  const [selectedTab, setSelectedTab] = useState("mySchedules");
  const [slots, setSlots] = useState([
    {
      shift: "morning",
      data: [],
    },
    { shift: "afternoon", data: [] },
    { shift: "evening", data: [] },
  ]);
  const [durationOfSlot, setDurationOfSlot] = useState(30);
  const [validRange, setValidRange] = useState([
    moment().clone().startOf("day"),
    moment().clone().endOf("month"),
  ]);
  const [selectedDates, setSelectedDates] = useState([
    moment().startOf("day").toISOString(),
  ]);
  const [days, setDays] = useState({
    [moment().month()]: [...defaultDays],
  });

  // Edit Slots states
  const [isEditing, setIsEditing] = useState(false);

  // book slot states
  const [selectedSlotId, setSelectedSlotId] = useState(NaN);
  const [step, setStep] = useState(1);
  const [meetingData, setMeetingData] = useState({
    name: "",
    note: "",
    topics: [],
  });
  const [otherTopics, setOtherTopics] = useState("");
  const [selectedMeetType, setSelectedMeetType] = useState("");
  const [allSelectedStudentForMeeting, setAllSelectedStudentForMeeting] =
    useState([]);
  const [isGroupMeeting, setIsGroupMeeting] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openEventLoginModal, setOpenEventLoginModal] = useState({
    type: null,
    isOpen: false,
  });

  // Filter states
  const [isOpenBookingFiltersModel, setIsOpenBookingFiltersModel] =
    useState(false);
  const [filterTutorsParameters, setFilterTutorsParameters] = useState(null);
  const [selectedFilterTutorsParameters, setSelectedFilterTutorsParameters] =
    useState({ specialities: [], fieldsOfStudy: [], yearsOfStudy: [] });

  // Edit Schedule
  const [editingScheduleData, setEditingScheduleData] = useState(null);

  // Reviews
  const [reviews, setReviews] = useState([]);
  const [isLoadingReviews, setIsLoadingReviews] = useState(false);

  const handleResetDays = (month) => {
    let clonedDays = { ...days };
    clonedDays[month] = clonedDays[month].map((day) => ({
      ...day,
      isSelected: false,
    }));
    setDays(clonedDays);
  };

  const handleSelectDate = (value) => {
    isEditing && handleCancelEditing();

    const isSelected = selectedDates.find((date) => moment(date).isSame(value));
    if (isSelected) {
      const newSelectedDates = selectedDates.filter(
        (date) => !moment(date).isSame(value)
      );
      setSelectedDates(newSelectedDates);
      if (newSelectedDates.length) {
        getScheduledSlotsBySelectedDates(newSelectedDates);
      } else {
        setScheduledSlots([]);
        setIsShowingScheduledSlots(false);
      }
    } else {
      const newSelectedDates = [...selectedDates, moment(value).toISOString()];
      setSelectedDates(newSelectedDates);
      getScheduledSlotsBySelectedDates(newSelectedDates);
    }
  };

  const handleSelectDays = (days, checked) => {
    isEditing && handleCancelEditing();
    let oldDates = [];
    const newDates = days.filter(
      (date) =>
        !selectedDates.find((selectedDate) => moment(selectedDate).isSame(date))
    );
    if (!checked) {
      oldDates = days.filter((date) =>
        selectedDates.find((selectedDate) => moment(selectedDate).isSame(date))
      );
    }
    const newSelectedDates = [...selectedDates, ...newDates].filter(
      (date) => !oldDates.find((oldDate) => moment(oldDate).isSame(date))
    );
    if (newSelectedDates.length) {
      getScheduledSlotsBySelectedDates(newSelectedDates);
    } else {
      setScheduledSlots([]);
      setIsShowingScheduledSlots(false);
    }

    setSelectedDates(newSelectedDates);
  };

  function getAllDaysOfMonth(day, month, year, checked) {
    const daysOfWeek = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
    const dayIndex = daysOfWeek.indexOf(day.toLowerCase());

    if (dayIndex === -1) {
      throw new Error("Invalid day provided");
    }

    const today = moment().startOf("day");
    const startOfMonth = moment(`${year}-${month}-01`, "YYYY-MM-DD");
    const endOfMonth = startOfMonth.clone().endOf("month");
    let currentDay = startOfMonth.clone().day(dayIndex);

    // If the first occurrence is in the previous month, move to the next week
    if (currentDay.isBefore(startOfMonth)) {
      currentDay.add(1, "week");
    }

    const dates = [];

    while (currentDay.isSameOrBefore(endOfMonth)) {
      if (currentDay.isSameOrAfter(today)) {
        dates.push(currentDay.toISOString());
      }
      currentDay.add(1, "week");
    }

    handleSelectDays(dates, checked);
  }

  function getShift(time) {
    const hour = time.hour();
    if (hour >= 6 && hour < 12) {
      return "morning";
    } else if (hour >= 12 && hour < 17) {
      return "afternoon";
    } else if (hour >= 17) {
      return "evening";
    }
  }

  function generateTimeSlots(date, duration, restTime) {
    const slots = {
      morning: [],
      afternoon: [],
      evening: [],
    };
    const currentDate = date.startOf("day");

    const endOfDay = currentDate.clone().endOf("day");
    let currentTime = currentDate.clone().hour(6).minute(0); // Start at 6 AM

    while (currentTime.isBefore(endOfDay)) {
      const endTime = currentTime.clone().add(duration, "minutes"); // assuming each slot is 30 minutes long

      if (endTime.isAfter(endOfDay)) {
        break;
      }

      const shift = getShift(currentTime);

      if (shift !== "none") {
        slots[shift].push({
          startTime: currentTime.format("hh:mm A"),
          endTime: endTime.format("hh:mm A"),
          shift: shift,
          status: "available",
        });
      }

      currentTime.add(duration + restTime, "minutes"); // move to the next slot
    }

    setSlots([
      {
        shift: "morning",
        data: slots.morning,
      },
      { shift: "afternoon", data: slots.afternoon },
      { shift: "evening", data: slots.evening },
    ]);
  }

  const getNextSixMonths = () => {
    const monthsFullNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const currentMonth = new Date().getMonth();
    const months = [];

    for (let i = 0; i < 6; i++) {
      const monthIndex = (currentMonth + i) % 12; // Loop over months array
      months.push(monthsFullNames[monthIndex]);
    }

    return months;
  };

  const disabledDate = (currentDate) => {
    return currentDate && currentDate < moment().startOf("day");
  };

  const dateFullCellRender = (value) => {
    const today = moment().startOf("day");
    const isToday = value.isSame(today, "day");
    const isSelectedDate = selectedDates.includes(
      value.startOf("day").toISOString()
    );

    const isCurrentMonth = value.isSame(date, "month");

    return (
      isCurrentMonth && (
        <div
          style={{
            backgroundColor: isSelectedDate ? "#6264a7" : "transparent",
            borderRadius: "5px",
            color: disabledDate(value)
              ? ""
              : isSelectedDate
              ? "white"
              : "black",
            border: isToday ? "1px solid white" : "",
            outline: isToday ? "1px solid #6264a7" : "",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          {value.date()}
        </div>
      )
    );
  };

  const isSlotSelected = (startTime) => {
    if (selectedSlots.length) {
      const index = selectedSlots.findIndex((slot) =>
        moment(slot.startTime, "h:mm A").isSame(
          moment(startTime, "h:mm A"),
          "minute"
        )
      );
      return index !== -1;
    } else {
      return false;
    }
  };

  const generateSlotsForAllSelectedDatesByTimeSlots = (
    selectedTimeSlots,
    selectedDates,
    scheduledSlots,
    type = "create"
  ) => {
    let slots = [];

    selectedDates.forEach((selectedDate) => {
      const slotsForSelectedDate = selectedTimeSlots.map(
        ({ startTime, endTime, shift, status }) => {
          const startMoment = moment(selectedDate)
            .clone()
            .hour(moment(startTime, "h:mm A").hour())
            .minute(moment(startTime, "h:mm A").minute());
          const endMoment = moment(selectedDate)
            .clone()
            .hour(moment(endTime, "h:mm A").hour())
            .minute(moment(endTime, "h:mm A").minute());

          return {
            tutorEmailId: props.user.mail,
            startTime: startMoment.toISOString(),
            endTime: endMoment.toISOString(),
            shift,
            status,
          };
        }
      );
      if (type === "create") {
        // Filter out slots that already exist in scheduledSlots
        const filteredSlotsForSelectedDate = slotsForSelectedDate.filter(
          (newSlot) => {
            return !scheduledSlots.some((scheduledSlot) => {
              return (
                moment(scheduledSlot.startTime).isSame(
                  moment(newSlot.startTime)
                ) &&
                moment(scheduledSlot.endTime).isSame(moment(newSlot.endTime))
              );
            });
          }
        );

        slots = slots.concat(filteredSlotsForSelectedDate); // Merge the arrays instead of creating nested arrays
      } else if (type === "edit") {
        slots = slots.concat(slotsForSelectedDate);
      }
    });

    return slots; // Return the generated slots array
  };

  const handleScheduleSlots = async () => {
    if (selectedSlots.length) {
      setIsScheduling(true);
      const slots = generateSlotsForAllSelectedDatesByTimeSlots(
        selectedSlots,
        selectedDates,
        scheduledSlots
      );
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_EP_URL}/api/slots${props.user.slug}`,
          { slots },
          {
            headers: { Authorization: "Bearer " + props.user.accessToken },
          }
        );
        setIsScheduling(false);
        if (res.data.success) {
          const successFullyScheduledSlots =
            res.data?.result?.createdSlots || [];
          setSelectedSlots([]);
          setScheduledSlots([...successFullyScheduledSlots, ...scheduledSlots]);
          [...successFullyScheduledSlots, ...scheduledSlots].length &&
            setIsShowingScheduledSlots(true);

          toast.success(
            `${successFullyScheduledSlots.length} Slots Scheduled SuccessFully!`
          );
        } else {
          toast.error(`Slots Scheduling Failed`);
        }
      } catch (error) {
        setIsScheduling(false);

        toast.error(`Slots Scheduling Failed`);
      }
    } else {
      toast.error("Select at least one slot.");
    }
  };

  const filterScheduledSlotsBySelectedDates = (slots, selectedDates) => {
    return slots.filter((slot) =>
      selectedDates.some((date) =>
        moment(date).isSame(moment(slot.startTime), "day")
      )
    );
  };

  const getScheduledSlots = async (startDate, endDate, selectedDates, mail) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_EP_URL}/api/slots${props.user.slug}&tutorEmailId=${mail}&startDate=${startDate}&endDate=${endDate}`
      );
      if (res.data?.slots) {
        const filteredSlots = filterScheduledSlotsBySelectedDates(
          res.data?.slots,
          selectedDates
        );
        setScheduledSlots(filteredSlots);

        let allSlotsTimes = [];
        slots.forEach(({ data }) => allSlotsTimes.push(...data));
        const isAnySlotScheduledForAllSelectedDate =
          checkIsAnySlotScheduledForAllSelectedDate(
            filteredSlots,
            selectedDates,
            allSlotsTimes
          );
        filteredSlots?.length && isAnySlotScheduledForAllSelectedDate
          ? setIsShowingScheduledSlots(true)
          : setIsShowingScheduledSlots(false);
      }
    } catch (error) {}
  };

  const isScheduledSlot = (startTime) => {
    if (scheduledSlots.length && selectedDates.length) {
      return selectedDates.every((date) => {
        return scheduledSlots.some((slot) =>
          moment(slot.startTime).isSame(
            moment(date)
              .hour(moment(startTime, "h:mm A").hour())
              .minute(moment(startTime, "h:mm A").minute()),
            "minute"
          )
        );
      });
    } else {
      return false;
    }
  };

  const isSlotBooked = (isScheduled, startTime) => {
    if (isScheduled) {
      // Iterate over each selected date
      return selectedDates.every((date) => {
        // Filter scheduled slots to match the start time on the given date
        const scheduledSlotsForDate = scheduledSlots.filter((slot) =>
          moment(slot.startTime).isSame(
            moment(date)
              .hour(moment(startTime, "h:mm A").hour())
              .minute(moment(startTime, "h:mm A").minute()),
            "minute"
          )
        );
        // Check if all filtered slots are booked
        return scheduledSlotsForDate.every((slot) => slot.status === "booked");
      });
    } else {
      return false;
    }
  };

  const checkIsAnySlotScheduledForAllSelectedDate = (
    scheduledSlotsOfAllSelectedDates,
    selectedDates,
    allSlotsTimes
  ) => {
    return selectedDates.some((selectedDate) => {
      // Find Slots with selectedDate
      const scheduledSlotsBySelectedDate =
        scheduledSlotsOfAllSelectedDates.filter(({ startTime }) => {
          const selectedMoment = moment(selectedDate);
          const startMoment = moment(startTime);
          return selectedMoment.isSame(startMoment, "day");
        });
      // Check is all slots have common timeSlot or not
      return scheduledSlotsBySelectedDate.every(({ startTime }) => {
        const scheduledTime = moment(startTime); // Convert to local time
        return allSlotsTimes.some((timeSlot) => {
          const time = moment(timeSlot.startTime, "hh:mm A"); // Convert to local time
          return scheduledTime.format("hh:mm A") === time.format("hh:mm A"); // Compare based on minutes
        });
      });
    });
  };

  const getCommonScheduledSlots = (
    scheduledSlotsOfAllSelectedDates,
    selectedDates,
    allSlotsTimes
  ) => {
    let commonTimeSlots = allSlotsTimes.filter((timeSlot) => {
      return selectedDates.every((selectedDate) => {
        // Find Slots with selectedDate
        const scheduledSlotsBySelectedDate =
          scheduledSlotsOfAllSelectedDates.filter(({ startTime }) => {
            const selectedMoment = moment(selectedDate);
            const startMoment = moment(startTime);
            return selectedMoment.isSame(startMoment, "day");
          });

        return scheduledSlotsBySelectedDate.some(({ startTime }) => {
          return moment(startTime).isSame(
            moment()
              .clone()
              .hour(moment(timeSlot.startTime, "hh:mm A").hour())
              .minute(moment(timeSlot.startTime, "hh:mm A").minute()),
            "minute"
          );
        });
      });
    });
    return commonTimeSlots;
  };

  const getScheduledSlotsBySelectedDates = (selectedDates) => {
    // Convert all dates to moment objects for comparison
    const momentDates = selectedDates.map((date) => moment(date));

    // Find the smallest and largest dates
    const smallestDate = moment.min(momentDates).toISOString();
    const largestDate = moment.max(momentDates).toISOString();

    // Get scheduled slots of all selected dates
    getScheduledSlots(
      moment(smallestDate).format("YYYY-MM-DD"),
      moment(largestDate).format("YYYY-MM-DD"),
      selectedDates,
      props.user.mail
    );
  };

  const handleStartEditingSlots = () => {
    setIsEditing(true);
    let allSlotsTimes = [];
    slots.forEach(({ data }) => allSlotsTimes.push(...data));
    const commonScheduledSlots = getCommonScheduledSlots(
      scheduledSlots,
      selectedDates,
      allSlotsTimes
    );
    setSelectedSlots(commonScheduledSlots);
    setIsShowingScheduledSlots(false);
  };

  const handleCancelEditing = () => {
    setIsEditing(false);
    setSelectedSlots([]);
    setIsShowingScheduledSlots(true);
  };

  const handleUpdateScheduledSlots = async () => {
    setIsScheduling(true);
    let newSlots = [];
    let oldRemovedSlotsIds = [];
    const slots = generateSlotsForAllSelectedDatesByTimeSlots(
      selectedSlots,
      selectedDates,
      scheduledSlots,
      "edit"
    );
    slots.map((slot) => {
      const isScheduled = scheduledSlots.some((scheduledSlot) =>
        moment(scheduledSlot.startTime).isSame(moment(slot.startTime))
      );
      !isScheduled &&
        newSlots.push({
          tutorEmailId: props.user.mail,
          ...slot,
        });
    });

    const oldRemovedSlots = scheduledSlots.filter(
      (scheduledSlot) =>
        !selectedSlots.some((selectedSlot) => {
          // Convert both times to the same timezone (UTC)
          const scheduledTime = moment(scheduledSlot.startTime).utc();
          const selectedTime = moment(selectedSlot.startTime, "hh:mm A").utc();

          return scheduledTime.isSame(selectedTime, "minute");
        })
    );

    if (oldRemovedSlots.length) {
      oldRemovedSlotsIds = oldRemovedSlots.map((slot) => slot.id);
    }
    if (newSlots.length || oldRemovedSlotsIds.length) {
      try {
        const res = await axios.put(
          `${process.env.REACT_APP_EP_URL}/api/slots${props.user.slug}`,
          { newSlots, oldRemovedSlotsIds },
          {
            headers: { Authorization: "Bearer " + props.user.accessToken },
          }
        );
        setIsScheduling(false);

        if (res.data.success) {
          const successFullyScheduledSlots =
            res.data?.result?.createdNewSlots || [];
          const removedSlotsIds = res.data?.result?.removedSlotsIds || [];
          setSelectedSlots([]);
          let newCreatedSlots = [
            ...successFullyScheduledSlots,
            ...scheduledSlots,
          ];
          if (removedSlotsIds.length) {
            newCreatedSlots = newCreatedSlots.filter(
              (slot) => !removedSlotsIds.includes(slot.id)
            );
          }
          setScheduledSlots(newCreatedSlots);
          newCreatedSlots.length && setIsShowingScheduledSlots(true);
          setIsEditing(false);
          successFullyScheduledSlots.length &&
            toast.success(
              `${successFullyScheduledSlots.length} ${
                successFullyScheduledSlots.length === 1 ? "Slot" : "Slots"
              } Added SuccessFully!`
            );
          removedSlotsIds.length &&
            toast.success(
              `${removedSlotsIds.length} ${
                removedSlotsIds.length === 1 ? "Slot" : "Slots"
              } Removed SuccessFully!`
            );
        } else {
          toast.error("Slots updating failed");
        }
      } catch (error) {
        toast.error("Slots updating failed");
        setIsScheduling(true);
      }
    } else {
      toast.error("Change something for update.");
      setIsScheduling(false);
    }
  };

  const handleToggleDay = (month, i) => {
    let clonedDays = { ...days };
    clonedDays[month][i].isSelected = !clonedDays[month][i].isSelected;
    setDays(clonedDays);
  };

  const handleChangeMonth = (month) => {
    setScheduledSlots([]);
    setSelectedSlots([]);
    setIsShowingScheduledSlots(false);
    setSelectedDates([]);
    const selectedDate = moment().clone().month(month);
    setDate(selectedDate);
    if (selectedDate.isSame(moment(), "month")) {
      setValidRange([moment(), selectedDate.clone().endOf("month")]);
    } else {
      if (!days[selectedDate.clone().month()]) {
        setDays({
          ...days,
          [selectedDate.clone().month()]: defaultDays.map((day) => ({
            ...day,
            isSelected: false,
          })),
        });
      }

      setValidRange([
        selectedDate.clone().startOf("month"),
        selectedDate.clone().endOf("month"),
      ]);
    }
  };

  const handleDurationChange = (e, { value }) => {
    const duration = parseInt(value.split(" ")[0], 10); // Extract the numeric value
    setDurationOfSlot(duration);
    generateTimeSlots(moment(), duration, 0);
    console.log("Selected duration:", duration);
  };

  // Tutors
  const handleGetTutors = async () => {
    setIsLoadingTutors(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_EP_URL}/api/tutor${props.user.slug}`,
        {
          headers: { Authorization: "Bearer " + props.user.accessToken },
        }
      );
      setIsLoadingTutors(false);

      if (res.data.success) {
        const tutors = res.data.result.tutors;
        setTutors(tutors);
      }
    } catch (error) {
      setIsLoadingTutors(false);
    }
  };

  const getTutorProfileData = async (mail) => {
    try {
      //   setIsLoadingData(true);
      const { slug, accessToken } = props.user;
      const { data } = await axios.get(
        `${url}/api/profile/get-profile/${mail}${slug}`,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      if (data?.data) {
        let profileData = {
          ...data.data,
          skills: data.data.ProfileSkills,
          interests: data.data.ProfileInterests,
        };
        // setUserProfileData(profileData);
      } else {
        // setUserProfileData({});
      }
    } catch (error) {
    } finally {
      //   setIsLoadingData(false);
    }
  };

  const handleBookSlot = async () => {
    let topics = meetingData.topics;

    if (meetingData.topics.includes("Other")) {
      const otherTopics_ = otherTopics.split(",");
      topics = [
        ...topics.filter((topic) => topic !== "Other"),
        ...otherTopics_,
      ];
    }
    if (
      meetingData.name &&
      meetingData.note &&
      topics.length &&
      selectedMeetType
    ) {
      setIsSubmitting(true);
      let meetToken = "";
      let meetData = {};
      if (selectedMeetType === "isGoogleMeetMeeting") {
        let GoogleMeetToken = JSON.parse(
          localStorage.getItem("GoogleMeetToken") || "{}"
        );
        meetToken = GoogleMeetToken?.accessToken;
        meetData = {
          meetType: "google",
          mail: GoogleMeetToken?.profileObj?.email,
        };
      } else if (selectedMeetType === "isMSTeamMeeting") {
        let MSTeamsToken = localStorage.getItem("MSTeamsToken");
        meetToken = MSTeamsToken;
        meetData = {
          meetType: "microsoft",
          mail: props.user.mail,
        };
      }
      let participants = [props.user.mail];
      allSelectedStudentForMeeting.forEach((participant) => {
        !participants.includes(participant.key) &&
          participants.push(participant.key);
      });
      const payload = {
        slotId: selectedSlotId,
        title: meetingData.name,
        note: meetingData.note,
        topics: topics.join(", "),
        meetData,
        isGroupMeeting,
        participants,
      };
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_EP_URL}/api/bookings${props.user.slug}`,
          payload,
          {
            headers: {
              Authorization: "Bearer " + props.user.accessToken,
              meetToken,
            },
          }
        );
        setIsSubmitting(false);

        if (res.data.success) {
          toast.success("Meeting Scheduled Successfully!");
          setStep(1);
          setMeetingData({ name: "", note: "", topics: [] });
          setOtherTopics("");
          setShowError(false);
          history.push("/booking");
          setSelectedTab("mySchedules");
        }
      } catch (error) {
        setIsSubmitting(false);
      }
    } else {
      setShowError(true);
    }
  };

  const handleUpdateBookedSlot = async () => {
    try {
      const editingSchedule =
        JSON.parse(localStorage.getItem("editingSchedule") || "{}") || null;

      if (editingSchedule) {
        let meetToken = "";
        let meetData = {};
        if (editingSchedule.meetType === "google") {
          let GoogleMeetToken = JSON.parse(
            localStorage.getItem("GoogleMeetToken") || "{}"
          );
          if (GoogleMeetToken) {
            meetToken = GoogleMeetToken?.accessToken;
            meetData = {
              meetType: "google",
              mail: GoogleMeetToken?.profileObj?.email,
            };
          } else {
            return handleLogin("isGoogleMeetMeeting");
          }
        } else if (editingSchedule.meetType === "microsoft") {
          let MSTeamsToken = localStorage.getItem("MSTeamsToken");
          if (MSTeamsToken) {
            meetToken = MSTeamsToken;
            meetData = {
              meetType: "microsoft",
              mail: props.user.mail,
            };
          } else {
            return handleLogin("isMSTeamMeeting");
          }
        }

        let payload = {
          bookingId: editingSchedule.id,
          newSlotId: selectedSlotId,
          meetData,
        };
        if (meetToken && meetData && selectedSlotId && editingSchedule.id) {
          setIsSubmitting(true);
          const res = await axios.put(
            `${process.env.REACT_APP_EP_URL}/api/bookings${props.user.slug}`,
            payload,
            {
              headers: {
                Authorization: "Bearer " + props.user.accessToken,
                meetToken,
              },
            }
          );
          setIsSubmitting(false);
          if (res.data.success) {
            toast.success("Schedule updated successfully!");
            history.push("/booking");
            setSelectedTab("mySchedules");
          } else {
            toast.error("Schedule updating failed!");
          }
        } else {
          toast.error("Fields are missing!");
        }
      }
    } catch (error) {
      toast.error(error.message);
      setIsSubmitting(false);
    }
  };

  const getSchedulesMeetings = async () => {
    try {
      setIsLoadingBookings(true);
      const endPoint = props.user.isTutor
        ? `/get-by-tutor`
        : `/get-by-participant`;
      const res = await axios.get(
        `${process.env.REACT_APP_EP_URL}/api/bookings${endPoint}/${props.user.mail}${props.user.slug}`,
        {
          headers: { Authorization: "Bearer " + props.user.accessToken },
        }
      );
      setIsLoadingBookings(false);

      if (res.data.success) {
        setBookings(res.data.result);
        setFilteredBookings(res.data.result);
      }
    } catch (error) {
      setIsLoadingBookings(false);
    }
  };

  const addTutorToStarred = async (tutorEmailId) => {
    const payload = { userEmailId: user.mail, tutorEmailId };
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_EP_URL}/api/tutor/add-to-stared/${props.user.slug}`,
        payload,
        {
          headers: { Authorization: "Bearer " + props.user.accessToken },
        }
      );
      if (res.data.success) {
        toast.success("Tutor Added to starred Successfully!");
        const starredTutor = res.data.result;
        setStarredTutors((prev) => [...prev, starredTutor]);
      } else {
        toast.error("Tutor Adding to starred Failed!");
      }
    } catch (error) {
      toast.error("Tutor Adding to starred Failed!");
    }
  };
  const getStarredTutors = async () => {
    try {
      setIsLoadingStarredTutors(true);
      const res = await axios.get(
        `${process.env.REACT_APP_EP_URL}/api/tutor/get-stared-tutors/${props.user.mail}${props.user.slug}`,
        {
          headers: { Authorization: "Bearer " + props.user.accessToken },
        }
      );
      setIsLoadingStarredTutors(false);
      if (res.data.success) {
        const starredTutor = res.data.result;
        setStarredTutors(starredTutor.length ? starredTutor : []);
      } else {
        setStarredTutors([]);
      }
    } catch (error) {
      setIsLoadingStarredTutors(false);
    }
  };

  const removeStarredTutor = async (tutorEmailId) => {
    try {
      const tutor = starredTutors.find(
        (tutor) => tutor.tutorEmailId === tutorEmailId
      );
      console.log("tutor", tutor);
      const res = await axios.delete(
        `${process.env.REACT_APP_EP_URL}/api/tutor/remove-stared-tutor/${tutor.id}${props.user.slug}`,
        {
          headers: { Authorization: "Bearer " + props.user.accessToken },
        }
      );
      if (res.data.success) {
        toast.success("Tutor removed from starred Successfully!");
        setStarredTutors((pre) => {
          const filteredTutors = pre.length
            ? pre.filter((tutor_) => tutor?.id !== tutor_?.id)
            : [];
          return filteredTutors;
        });
      } else {
        toast.error("Tutor removing from starred Failed!");
      }
    } catch (error) {
      console.log("error", error);
      toast.error("Tutor removing from starred Failed!");
    }
  };

  const checkIsTutorStarred = (tutorEmailId) => {
    return starredTutors.some((tutor) => tutor.tutorEmailId === tutorEmailId);
  };

  const onSuccess = (res) => {
    localStorage.setItem("GoogleMeetToken", JSON.stringify(res));
  };

  const onFailure = (res) => {
    setSelectedMeetType("");
    if (typeof res?.details === "string") {
      if (res.error === "idpiframe_initialization_failed") {
        // NotificationAlert(eventTranslation.disabledCookiesWarning, "warning");
        NotificationAlert(
          "Cookies are not enabled. Please enable it if you want to create Google Meet.",
          "warning"
        );
      } else {
        NotificationAlert(res.details, "warning");
      }
    }
  };

  const handleMSLogin = (value) => {
    const instance = new PublicClientApplication({ ...msalConfig, cache: {} });
    instance
      .loginPopup(loginRequest)
      .then((data) => {
        localStorage.setItem("MSTeamsToken", JSON.stringify(data));
      })
      .catch((e) => {
        setSelectedMeetType("");
      });
  };

  const googleLoginHandler = () => {
    const googleLoginBtn = document.querySelector(".google-login-btn");
    if (googleLoginBtn) {
      googleLoginBtn.click();
    }
  };

  const handleLogin = (type) => {
    if (type === "isMSTeamMeeting") {
      const isMSLogin = props.user.authType === "microsoft";
      if (isMSLogin) {
        localStorage.setItem("MSTeamsToken", props.user.accessToken);
      } else {
        let token = localStorage.getItem("MSTeamsToken");
        if (token) {
          handleMSLogin();
        } else {
          setOpenEventLoginModal({
            type: "isMSTeamMeeting",
            isOpen: true,
          });
        }
      }
    } else if (type === "isGoogleMeetMeeting") {
      const isGoogleLogin = props.user.authType === "google";
      if (isGoogleLogin) {
        googleLoginHandler();
      } else {
        let tokenData = localStorage.getItem("GoogleMeetToken");
        let token = null;

        if (tokenData) {
          try {
            token = JSON.parse(tokenData)?.accessToken;
          } catch (error) {}
        }

        if (token) {
          googleLoginHandler();
        } else {
          setOpenEventLoginModal({
            type: "isGoogleMeetMeeting",
            isOpen: true,
          });
        }
      }
    }
  };

  const getFilterTutorsParameters = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_EP_URL}/api/bookings/get-filter-tutor-parameters${props.user.slug}`,
        {
          headers: { Authorization: "Bearer " + props.user.accessToken },
        }
      );
      if (res.data.success) {
        setFilterTutorsParameters(res.data.result);
      }
    } catch (error) {}
  };

  const filterTutors = async () => {
    const payload = {
      filterOptions: {
        fieldsOfStudy: selectedFilterTutorsParameters.fieldsOfStudy,
        yearsOfStudy: selectedFilterTutorsParameters.yearsOfStudy,
        specialities: selectedFilterTutorsParameters.specialities,
      },
    };
    setIsLoadingTutors(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_EP_URL}/api/tutor/filter-tutors${props.user.slug}`,
        payload,
        {
          headers: { Authorization: "Bearer " + props.user.accessToken },
        }
      );
      setIsLoadingTutors(false);

      if (res.data.success) {
        const tutors = res.data.result;
        setTutors(tutors);
      }
    } catch (error) {
      setIsLoadingTutors(false);
    }
  };

  const onResetFilter = () => {
    setSelectedFilterTutorsParameters({
      specialities: [],
      fieldsOfStudy: [],
      yearsOfStudy: [],
    });
  };

  const onApplyFilter = () => {
    filterTutors();
  };

  const getReviewsByTutor = async (tutorMailId) => {
    try {
      setIsLoadingReviews(true);
      const res = await axios.get(
        `${process.env.REACT_APP_EP_URL}/api/booking-review/get-by-tutor/${tutorMailId}${props.user.slug}`,
        {
          headers: { Authorization: "Bearer " + props.user.accessToken },
        }
      );
      setIsLoadingReviews(false);

      if (res.data.success) {
        const reviews = res.data.result;
        setReviews(reviews);
      }
    } catch (error) {
      setIsLoadingReviews(false);
    }
  };

  return (
    <Booking.Provider
      value={{
        date,
        bookings,
        setBookings,
        dateFullCellRender,
        validRange,
        selectedSlots,
        setSelectedSlots,
        generateTimeSlots,
        slots,
        setSlots,
        durationOfSlot,
        isSlotSelected,
        handleScheduleSlots,
        getScheduledSlots,
        isScheduling,
        isScheduledSlot,
        isShowingScheduledSlots,
        handleSelectDate,
        isEditing,
        handleUpdateScheduledSlots,
        handleStartEditingSlots,
        days,
        setDays,
        handleToggleDay,
        getAllDaysOfMonth,
        handleChangeMonth,
        handleResetDays,
        selectedDates,
        isSlotBooked,
        handleDurationChange,
        handleCancelEditing,
        handleGetTutors,
        tutors,
        selectedTab,
        setSelectedTab,
        getTutorProfileData,
        user,
        scheduledSlots,
        selectedSlotId,
        setSelectedSlotId,
        step,
        setStep,
        meetingData,
        setMeetingData,
        otherTopics,
        setOtherTopics,
        showError,
        handleBookSlot,
        getSchedulesMeetings,
        starredTutors,
        getStarredTutors,
        removeStarredTutor,
        addTutorToStarred,
        checkIsTutorStarred,
        handleMSLogin,
        onFailure,
        onSuccess,
        googleLoginHandler,
        selectedMeetType,
        setSelectedMeetType,
        openEventLoginModal,
        setOpenEventLoginModal,
        handleLogin,
        isOpenBookingFiltersModel,
        setIsOpenBookingFiltersModel,
        isLoadingTutors,
        getFilterTutorsParameters,
        filterTutorsParameters,
        selectedFilterTutorsParameters,
        setSelectedFilterTutorsParameters,
        onResetFilter,
        onApplyFilter,
        globalSearch,
        setGlobalSearch,
        isLoadingStarredTutors,
        isLoadingBookings,
        isSubmitting,
        editingScheduleData,
        setEditingScheduleData,
        setSelectedDates,
        handleUpdateBookedSlot,
        getReviewsByTutor,
        isLoadingReviews,
        reviews,
        isGroupMeeting,
        setIsGroupMeeting,
        allSelectedStudentForMeeting,
        setAllSelectedStudentForMeeting,
        getNextSixMonths,
        filteredBookings,
        setFilteredBookings,
      }}
    >
      {children}
    </Booking.Provider>
  );
};
