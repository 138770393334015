import React, { useState } from "react";
import {
  Button,
  Portal,
  CloseIcon,
  Checkbox,
  Pill,
} from "@fluentui/react-northstar";
import { Collapse } from "antd";
import { useTranslation, withTranslation } from "react-i18next";
import { Panel } from "@fluentui/react";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField } from "@mui/material";
import { TextField as FluentUITextField } from "@fluentui/react";

import { Flex } from "@fluentui/react-northstar";
import { useBooking } from "../../../../../context/BookingContext";

const BookingFilterModel = (props) => {
  const { open, onHandlePortal } = props;
  const { t } = useTranslation();

  const {
    filterTutorsParameters,
    selectedFilterTutorsParameters,
    setSelectedFilterTutorsParameters,
    onResetFilter,
    onApplyFilter,
  } = useBooking();

  const [categorySearch, setCategorySearch] = useState("");
  const [fieldSearch, setFieldSearch] = useState("");

  const translation = t("events");
  const filterTranslation = translation.body.userFilter;
  const controlsTranslation = translation.controls;

  const dismissPanel = () => {
    onHandlePortal(false);
  };

  return (
    <Panel
      isOpen={open}
      onDismiss={(e) => {
        if (e?.currentTarget?.tagName?.toLowerCase() === "button") {
          dismissPanel();
        }
      }}
      closeButtonAriaLabel="Close"
    >
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p>{filterTranslation.filters}</p>
        </div>
        <Collapse>
          <Collapse.Panel header={"Specialities"} key="1">
            <Flex column gap="gap.small">
              <FluentUITextField
                value={categorySearch}
                onChange={(e, value) => {
                  setCategorySearch(value);
                }}
                placeholder={"Search speciality"}
                style={{ background: "#F5F5F5", borderRadius: "5px" }}
                borderless={true}
              />

              <Flex wrap>
                {(categorySearch?.length &&
                filterTutorsParameters?.specialitiesOptions.length
                  ? filterTutorsParameters.specialitiesOptions.filter((c) =>
                      c.title
                        ?.toLowerCase()
                        ?.includes(categorySearch?.toLowerCase())
                    )
                  : filterTutorsParameters?.specialitiesOptions || []
                ).map((c, i) => (
                  <Pill
                    key={c.id}
                    selected={selectedFilterTutorsParameters.specialities.includes(
                      c.id
                    )}
                    selectable
                    onClick={(e) => {
                      if (
                        selectedFilterTutorsParameters.specialities.includes(
                          c.id
                        )
                      ) {
                        setSelectedFilterTutorsParameters({
                          ...selectedFilterTutorsParameters,
                          specialities:
                            selectedFilterTutorsParameters.specialities.filter(
                              (speciality) => speciality !== c.id
                            ),
                        });
                      } else {
                        setSelectedFilterTutorsParameters({
                          ...selectedFilterTutorsParameters,
                          specialities: [
                            ...selectedFilterTutorsParameters.specialities,
                            c.id,
                          ],
                        });
                      }
                    }}
                    style={{
                      minWidth: "initial",
                      backgroundColor: c.checked && "#6264A7",
                      color: c.checked ? "#fff" : "#000",
                    }}
                    size="small"
                  >
                    {c.title}
                  </Pill>
                ))}
              </Flex>
            </Flex>
          </Collapse.Panel>
          <Collapse.Panel header={"Year Of Study"} key="2">
            <Flex column gap="gap.small">
              <Flex wrap>
                {filterTutorsParameters?.yearOfStudyOptions.length
                  ? filterTutorsParameters.yearOfStudyOptions.map((c, i) => (
                      <Pill
                        key={i}
                        selected={selectedFilterTutorsParameters.yearsOfStudy.includes(
                          c
                        )}
                        selectable
                        onClick={(e) => {
                          if (
                            selectedFilterTutorsParameters.specialities.includes(
                              c
                            )
                          ) {
                            setSelectedFilterTutorsParameters({
                              ...selectedFilterTutorsParameters,
                              yearsOfStudy:
                                selectedFilterTutorsParameters.yearsOfStudy.filter(
                                  (yearOfStudy) => yearOfStudy !== c
                                ),
                            });
                          } else {
                            setSelectedFilterTutorsParameters({
                              ...selectedFilterTutorsParameters,
                              yearsOfStudy: [
                                ...selectedFilterTutorsParameters.yearsOfStudy,
                                c,
                              ],
                            });
                          }
                        }}
                        style={{
                          minWidth: "initial",
                          backgroundColor: c.checked && "#6264A7",
                          color: c.checked ? "#fff" : "#000",
                        }}
                        size="small"
                      >
                        {`Year ${c}`}
                      </Pill>
                    ))
                  : null}
              </Flex>
            </Flex>
          </Collapse.Panel>
          <Collapse.Panel header={"Field Of Study"} key="2">
            <Flex column gap="gap.small">
              <FluentUITextField
                value={fieldSearch}
                onChange={(e, value) => {
                  setFieldSearch(value);
                }}
                placeholder={"Search Field of Study"}
                style={{ background: "#F5F5F5", borderRadius: "5px" }}
                borderless={true}
              />

              <Flex wrap>
                {(fieldSearch?.length &&
                filterTutorsParameters?.fieldOfStudyOptions.length
                  ? filterTutorsParameters.fieldOfStudyOptions.filter((c) =>
                      c?.toLowerCase()?.includes(fieldSearch?.toLowerCase())
                    )
                  : filterTutorsParameters?.fieldOfStudyOptions || []
                ).map((c, i) => (
                  <Pill
                    key={`field-of-study-${i}`}
                    selected={selectedFilterTutorsParameters.fieldsOfStudy.includes(
                      c
                    )}
                    selectable
                    onClick={(e) => {
                      if (
                        selectedFilterTutorsParameters.fieldsOfStudy.includes(c)
                      ) {
                        setSelectedFilterTutorsParameters({
                          ...selectedFilterTutorsParameters,
                          fieldsOfStudy:
                            selectedFilterTutorsParameters.fieldsOfStudy.filter(
                              (fieldOfStudy) => fieldOfStudy !== c
                            ),
                        });
                      } else {
                        setSelectedFilterTutorsParameters({
                          ...selectedFilterTutorsParameters,
                          fieldsOfStudy: [
                            ...selectedFilterTutorsParameters.fieldsOfStudy,
                            c,
                          ],
                        });
                      }
                    }}
                    style={{
                      minWidth: "initial",
                      backgroundColor: c.checked && "#6264A7",
                      color: c.checked ? "#fff" : "#000",
                    }}
                    size="small"
                  >
                    {c}
                  </Pill>
                ))}
              </Flex>
            </Flex>
          </Collapse.Panel>
        </Collapse>
        <div
          style={{
            border: 0,
            display: "flex",
            justifyContent: "space-between",
            marginTop: 40,
          }}
        >
          <Button
            content={filterTranslation.clearAll}
            text
            onClick={() => onResetFilter()}
          />
          <Button
            content={filterTranslation.apply}
            onClick={() => onApplyFilter()}
          />
        </div>
      </div>
    </Panel>
  );
};

export default withTranslation()(BookingFilterModel);
