import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { withTranslation } from "react-i18next";

import "./TutorProfile.scss";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { NotificationAlert } from "../../../../components/Notification/Notification";
import Header from "../../../../components/Header/Header";
import { useBooking } from "../../../../context/BookingContext";
import Profile from "./Profile";

const url = process.env.REACT_APP_EP_URL;

const TutorProfile = (props) => {
  const { mail, role } = useParams();
  // const { getTutorProfileData } = useBooking();
  const { t } = props;

  const translation = t("feedback");
  const feedbackTranslation = translation.body.mainPage;

  const [userProfileData, setUserProfileData] = useState({});
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 767);

  const history = useHistory();

  const { getReviewsByTutor } = useBooking();

  const getProfileData = async () => {
    try {
      setIsLoadingData(true);
      const { slug, accessToken } = props.user;
      const { data } = await axios.get(
        `${url}/api/tutor/get-tutor-info/${mail}${slug}&role=${role}`,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      if (data) {
        let profileData = {
          ...data,
          skills: data.ProfileSkills,
          interests: data.ProfileInterests,
        };
        setUserProfileData(profileData);
      } else {
        setUserProfileData({});
      }
    } catch (error) {
      NotificationAlert(
        typeof error?.response?.data?.message === "string"
          ? error.response.data.message
          : feedbackTranslation.saveError,
        "error"
      );
    } finally {
      setIsLoadingData(false);
    }
  };

  useEffect(() => {
    getProfileData();
  }, []);

  useEffect(() => {
    mail && getReviewsByTutor(mail);
  }, [mail]);

  return (
    <div>
      <Header
        path="booking"
        user={{ ...props.user }}
        logout={props.logout.bind()}
        accessToken={props.user.accessToken}
        getDb={props.getDb.bind()}
        switchDb={props.switchDb}
        selectedExtension={props.selectedExtension}
        setSelectedExtension={props.setSelectedExtension}
      />
      <Profile
        userProfileData={userProfileData}
        isMobileView={isMobileView}
        {...props}
      />
    </div>
  );
};
export default withTranslation()(TutorProfile);
