import { Layout, Menu } from "antd";
import React from "react";
import { Clock } from "react-feather";
import "./SideBar.scss";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import { useBooking } from "../../../../context/BookingContext";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import { CalendarIcon, StarIcon } from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
const { Sider } = Layout;

const SideBar = (props) => {
  const { t } = useTranslation();
  const { selectedTab, setSelectedTab } = useBooking();
  const style = {
    background: "#F5F5F5",
    boxShadow: "2px 0px rgba(0, 0, 0, 0.03)",
    marginTop: 2,
    position: props.isMobileView && "absolute",
    zIndex: props.isMobileView && "2",
    height: props.isMobileView && "calc(100% - 172px)",
  };

  const translation = t("booking").sidebar;

  return (
    <Sider
      breakpoint="md"
      collapsedWidth={"0"}
      theme={"light"}
      style={style}
      className="booking-sidebar-wrapper"
    >
      <Menu
        theme="light"
        style={{ background: "#F5F5F5", paddingTop: "10px" }}
        mode="inline"
        selectedKeys={selectedTab}
      >
        {props?.user?.isTutor ? (
          <Menu.Item
            key="Schedules"
            icon={<AccessTimeFilledIcon />}
            onClick={() => {
              setSelectedTab("Schedules");
            }}
          >
            {translation.headers.setMyAvailability}
          </Menu.Item>
        ) : null}
        <Menu.Item
          key="Tutors"
          icon={<SupervisorAccountIcon />}
          onClick={() => {
            setSelectedTab("Tutors");
          }}
        >
          {translation.headers.tutors}
        </Menu.Item>
        <Menu.Item
          key="mySchedules"
          icon={<CalendarIcon />}
          onClick={() => {
            setSelectedTab("mySchedules");
          }}
        >
          {translation.headers.mySchedules}
        </Menu.Item>
        <Menu.Item
          key="starredTutors"
          icon={<StarIcon />}
          onClick={() => {
            setSelectedTab("starredTutors");
          }}
        >
          {translation.headers.Starred}
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default SideBar;
