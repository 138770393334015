import { Checkbox, Flex, Image, Loader } from "@fluentui/react-northstar";
import React, { useEffect, useState } from "react";
import MeetingCard from "./MeetingCard/MeetingCard";
import { useBooking } from "../../../../context/BookingContext";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import "./MySchedules.scss";
import NoEventFoundImage from "../../../../Assets/images/svg/NoAssignmentFound.svg";

const MySchedules = () => {
  const { t } = useTranslation();

  const {
    getSchedulesMeetings,
    bookings,
    isLoadingBookings,
    filteredBookings,
    setFilteredBookings,
    user,
  } = useBooking();

  const handleShowMeetingsWithTutors = (isShow) => {
    if (isShow) {
      const filteredBookings = bookings.filter(
        (booking) => booking.tutorData.email !== user.mail
      );
      setFilteredBookings(filteredBookings);
    } else {
      setFilteredBookings(bookings);
    }
  };
  useEffect(() => {
    getSchedulesMeetings();
  }, []);
  const translation = t("booking").mySchedules;
  return (
    <Flex column className="my-schedules-wrapper">
      <Flex space="between" vAlign="center">
        <h1 style={{ color: "#6264a7", fontWeight: "bold" }}>
          {translation.header}
        </h1>
        <Checkbox
          label={"Meetings With Tutor"}
          onChange={(e, val) => {
            handleShowMeetingsWithTutors(val.checked);
          }}
          toggle
          disabled={isLoadingBookings}
          color="#6264a7"
        />
      </Flex>
      {isLoadingBookings ? (
        <Flex hAlign="center" vAlign="center" style={{ height: "100%" }}>
          <Loader size="medium" />
        </Flex>
      ) : filteredBookings?.length ? (
        <div className="schedule-cards-wrapper">
          <Row gutter={[20, 20]} style={{ margin: 0 }}>
            {filteredBookings.map((data, i) => (
              <Col key={i} xl={8} md={12} sm={24}>
                <MeetingCard data={data} />
              </Col>
            ))}
          </Row>
        </div>
      ) : (
        <Flex column hAlign="center" vAlign="center" style={{ height: "100%" }}>
          <Image
            style={{ maxWidth: "280px", width: "100%" }}
            src={NoEventFoundImage}
          />
          <span>{translation.noSchedulesFound}</span>
        </Flex>
      )}
    </Flex>
  );
};

export default MySchedules;
