import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import SideBar from "./Components/SideBar/SideBar";
import "./index.scss";
import { Layout } from "antd";
import Schedules from "./Components/Schedules/Schedules";
import Tutors from "./Components/Tutors/Tutors";
import { useBooking } from "../../context/BookingContext";
import MySchedules from "./Components/MySchedules/MySchedules";
import Starred from "./Components/Starred/Starred";
import BookingFilter from "./Components/BookingFilter/BookingFilter";
import BookingFilterModel from "./Components/BookingFilter/BookingFilterModel/BookingFilterModel";
import { Flex } from "@fluentui/react-northstar";
const { Content } = Layout;
const Booking = (props) => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 767);
  const [isTabletView, setIsTabletView] = useState(
    window.innerWidth > 767 && window.innerWidth <= 991
  );
  const {
    selectedTab,
    setSelectedTab,
    isOpenBookingFiltersModel,
    setIsOpenBookingFiltersModel,
    getFilterTutorsParameters,
  } = useBooking();

  useEffect(() => {
    getFilterTutorsParameters();
  }, []);
  return (
    <div className="booking-wrapper">
      <Header
        path="booking"
        user={{ ...props.user }}
        logout={props.logout.bind()}
        accessToken={props.user.accessToken}
        getDb={props.getDb.bind()}
        switchDb={props.switchDb}
        selectedExtension={props.selectedExtension}
        setSelectedExtension={props.setSelectedExtension}
      />
      {selectedTab === "Tutors" ? (
        <BookingFilterModel
          open={isOpenBookingFiltersModel}
          onHandlePortal={(data) => {
            setIsOpenBookingFiltersModel(data);
          }}
        />
      ) : null}
      <Flex column>
        {selectedTab === "Tutors" ? (
          <BookingFilter
            onHandlePortal={(data) => {
              setIsOpenBookingFiltersModel(data);
            }}
            isMobileView={isMobileView}
            isTabletView={isTabletView}
          />
        ) : null}
        <Layout>
          <SideBar
            isMobileView={isMobileView}
            isTabletView={isTabletView}
            user={{ ...props.user }}
          />
          <Content style={{ padding: "20px 20px 35px 40px" }}>
            {selectedTab === "Tutors" ? (
              <Tutors />
            ) : selectedTab === "Schedules" ? (
              <Schedules isMobileView={isMobileView} />
            ) : selectedTab === "mySchedules" ? (
              <MySchedules />
            ) : selectedTab === "starredTutors" ? (
              <Starred />
            ) : null}
          </Content>
        </Layout>
      </Flex>
    </div>
  );
};

export default Booking;
