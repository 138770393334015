import React, { useEffect, useState } from "react";
import {
  Card,
  Flex,
  Text,
  Button,
  Box,
  Divider,
  Label,
  Dialog,
  Input,
  Tooltip,
} from "@fluentui/react-northstar";
import {
  EditIcon,
  CallVideoIcon,
  ShareGenericIcon,
  ClipboardCopiedToIcon,
  CloseIcon,
  ChatIcon,
} from "@fluentui/react-icons-northstar";
import "./MeetingCard.scss";
import moment from "moment";
import { useBooking } from "../../../../../context/BookingContext";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ReviewFormModel from "../../ReviewFormModel/ReviewFormModel";
import { Avatar } from "antd";
import { useTranslation } from "react-i18next";

const MeetingCard = ({ data }) => {
  const { t } = useTranslation();

  const topics = data?.topics.split(",") ?? [];
  const { user, setEditingScheduleData } = useBooking();
  const history = useHistory();
  const tutorData = data.tutorData;
  const [isOpenShareMeetDialog, setIsOpenShareMeetDialog] = useState(false);
  const [isOpenReviewDialog, setIsOpenReviewDialog] = useState(false);

  const copyMeetLink = async () => {
    if (data?.meetLink) {
      try {
        navigator.clipboard.writeText(data.meetLink);
        toast.success("Link copied!", {
          closeOnClick: true,
        });
      } catch {
        toast.error("Link coping Failed!", {
          closeOnClick: true,
        });
      }
    }
  };

  const isReviewSubmitted = data.participants.some(
    (participant) =>
      participant.email === user.mail && participant.isReviewSubmitted
  );

  const translation = t("booking").mySchedules.meetingCard;
  const commonTranslation = t("booking").common;
  const shareMeetingDialogTranslation = t("booking").shareMeetingDialog;

  const isUserIsTutorOfMeeting = user.mail === data.tutorData.email;

  return (
    <>
      <Dialog
        open={isOpenShareMeetDialog}
        closeOnOutsideClick={true}
        content={
          <Flex gap="gap.small" style={{ width: "100%", marginTop: "20px" }}>
            <Input value={data.meetLink} className="meet-link-wrapper" />
            <Button
              iconOnly
              icon={<ClipboardCopiedToIcon />}
              onClick={copyMeetLink}
            />
          </Flex>
        }
        header={shareMeetingDialogTranslation.header}
        style={{ maxWidth: "500px" }}
        headerAction={{
          icon: <CloseIcon />,
          title: commonTranslation.close,
          onClick: () => {
            setIsOpenShareMeetDialog(false);
          },
        }}
      />

      {isOpenReviewDialog && (
        <ReviewFormModel
          open={isOpenReviewDialog}
          onClose={() => setIsOpenReviewDialog(false)}
          meetingData={data}
        />
      )}
      <Card
        aria-roledescription="card with meeting information"
        className="meeting-card"
        size="large"
      >
        <Card.Header>
          <Flex space="between" vAlign="center">
            <Flex gap="gap.small">
              <Text
                content={moment(data.slot.startTime).format("MMM DD, YYYY")}
                weight="bold"
                color="brand"
              />
              <Text
                content={moment(data.slot.startTime).format("h:mm A")}
                weight="bold"
                color="brand"
              />
            </Flex>
            {!user?.isTutor && (
              <Button
                icon={<ShareGenericIcon />}
                text
                iconOnly
                onClick={() => {
                  setIsOpenShareMeetDialog(true);
                }}
              />
            )}
          </Flex>
        </Card.Header>
        <Card.Body>
          <Text content={data?.title ?? ""} weight="bold" size="large" />
          <Flex gap="gap.small" style={{ marginTop: "10px" }}>
            {topics.map((topic, i) => (
              <Label
                key={i}
                content={topic}
                circular
                color="brand"
                className="badge"
                primary
              />
            ))}
          </Flex>
          <Divider />
        </Card.Body>
        <Card.Footer>
          <Flex space="between">
            <Flex column gap="gap.smaller">
              <Flex gap="gap.small" vAlign="center">
                <Avatar src={tutorData?.image}>{tutorData?.name}</Avatar>
                <Text content={`${tutorData?.name} (Tutor)`} />
              </Flex>
              {data?.participants?.length === 1 ? (
                <Flex gap="gap.small" vAlign="center">
                  <Avatar src={data?.participants?.[0]?.image || ""}>
                    {data?.participants?.[0]?.name?.[0] || ""}
                  </Avatar>
                  <Text content={data?.participants?.[0]?.name || ""} />
                </Flex>
              ) : data?.participants?.length > 1 ? (
                <Flex gap="gap.small" vAlign="center">
                  <Avatar.Group maxCount={2}>
                    {data?.participants.map((participant, i) => (
                      <Tooltip
                        key={`participant-${i}`}
                        trigger={
                          <Avatar src={participant.image}>
                            {participant?.name?.[0]}
                          </Avatar>
                        }
                        content={participant.name}
                      />
                    ))}
                  </Avatar.Group>
                </Flex>
              ) : null}
            </Flex>

            <Flex gap="gap.medium" hAlign="end" vAlign="end">
              {!user?.isTutor && moment(data.slot.endTime).isAfter() && (
                <Button
                  icon={<EditIcon />}
                  text
                  iconOnly
                  onClick={() => {
                    localStorage.setItem(
                      "editingSchedule",
                      JSON.stringify(data)
                    );
                    setEditingScheduleData(data);
                    history.push(
                      `/edit-schedule-meeting/${tutorData.email}/${tutorData.role}/${data.slotId}`
                    );
                  }}
                />
              )}
              {isReviewSubmitted ? null : moment(
                  data.slot.endTime
                ).isBefore() ? (
                !user.isTutor && (
                  <Button
                    content={translation.giveFeedBackButton}
                    style={{ borderRadius: "5px" }}
                    onClick={() => setIsOpenReviewDialog(true)}
                    primary
                    icon={<ChatIcon />}
                  />
                )
              ) : (
                <Button
                  content={
                    isUserIsTutorOfMeeting
                      ? "Join Meeting"
                      : translation.startMeeting
                  }
                  style={{ borderRadius: "5px" }}
                  onClick={() => window.open(data?.meetLink, "_blank")}
                  primary
                  icon={<CallVideoIcon />}
                />
              )}
            </Flex>
          </Flex>
        </Card.Footer>
      </Card>
    </>
  );
};

export default MeetingCard;
